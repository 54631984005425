import { CircularProgress, CircularProgressProps } from '@mui/material';

export type VibSpinnerProps = CircularProgressProps;

export const VibSpinner = (props: VibSpinnerProps) => {
  return (
    <div className="flex items-center justify-center h-full">
      <CircularProgress {...props} />
    </div>
  );
};
