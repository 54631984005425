import { useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { styles } from './styles';
import { twMerge } from 'tailwind-merge';

export type InputDateProps = Omit<DatePickerProps<any>, 'value'> &
  Omit<DateTimePickerProps<any>, 'value'> &
  Pick<TextFieldProps, 'placeholder' | 'error' | 'helperText' | 'id'> & {
    type: 'date' | 'datetime';
    name: string;
    control: Control<any>;
    onChangeCallback?: (newValue: any) => void;
  };

export const InputDate = ({
  type,
  name,
  control,
  onChangeCallback,
  ...restProps
}: InputDateProps) => {
  const defaultValue = restProps.defaultValue
    ? dayjs(restProps.defaultValue)
    : '';
  const [value, setValue] = useState(defaultValue);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={restProps.defaultValue}
      render={({ field }) => {
        const handleChange = (newValue: any) => {
          setValue(newValue);
          field.onChange(newValue?.$d);
          onChangeCallback?.(newValue);
        };

        const pickerProps = {
          ...field,
          ...restProps,
          onChange: handleChange,
          value,
          className: twMerge(styles, restProps.className),
          slotProps: {
            textField: {
              error: restProps.error,
              helperText: restProps.helperText,
              placeholder: restProps.placeholder,
              id: restProps.id,
            },
          },
        };

        const renderDateInput = () => {
          switch (type) {
            case 'date':
              return <DatePicker {...pickerProps} />;
            case 'datetime':
              return <DateTimePicker {...pickerProps} />;
          }
        };

        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            {renderDateInput()}
          </LocalizationProvider>
        );
      }}
    />
  );
};
