import 'react-international-phone/style.css';

import {
  InputAdornment,
  MenuItem,
  Select,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { Control, Controller } from 'react-hook-form';

export type InputPhoneProps = Omit<StandardTextFieldProps, 'value'> & {
  name: string;
  control: Control<any>;
};

export const InputPhone = ({
  name,
  control,
  defaultValue,
  ...restProps
}: InputPhoneProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      render={({ field }) => {
        const {
          inputValue,
          handlePhoneValueChange,
          inputRef,
          country,
          setCountry,
        } = usePhoneInput({
          defaultCountry: 'br',
          value: field.value,
          countries: defaultCountries,
          onChange: (data) => {
            field.onChange(data.phone);
          },
        });

        return (
          <TextField
            variant="outlined"
            label="Telefone/Celular"
            color="primary"
            placeholder="Telefone/Celular"
            value={inputValue}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ marginRight: '2px', marginLeft: '-8px' }}
                >
                  <Select
                    MenuProps={{
                      style: {
                        height: '300px',
                        width: '360px',
                        top: '10px',
                        left: '-34px',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    sx={{
                      width: 'max-content',
                      // Remove default outline (display only on focus)
                      fieldset: {
                        display: 'none',
                      },
                      '&.Mui-focused:has(div[aria-expanded="false"])': {
                        fieldset: {
                          display: 'block',
                        },
                      },
                      // Update default spacing
                      '.MuiSelect-select': {
                        padding: '8px',
                        paddingRight: '24px !important',
                      },
                      svg: {
                        right: 0,
                      },
                    }}
                    value={country.iso2}
                    onChange={(e) => setCountry(e.target.value as CountryIso2)}
                    renderValue={(value) => (
                      <FlagImage iso2={value} style={{ display: 'flex' }} />
                    )}
                    disabled={restProps.disabled}
                  >
                    {defaultCountries.map((c) => {
                      const country = parseCountry(c);
                      return (
                        <MenuItem key={country.iso2} value={country.iso2}>
                          <FlagImage
                            iso2={country.iso2}
                            style={{ marginRight: '8px' }}
                          />
                          <Typography marginRight="8px">
                            {country.name}
                          </Typography>
                          <Typography color="gray">
                            +{country.dialCode}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </InputAdornment>
              ),
            }}
            {...restProps}
          />
        );
      }}
    />
  );
};
