import Compressor from 'compressorjs';

export const imageCompressor = (image: File): Promise<Blob> =>
  new Promise(
    (resolve, reject) =>
      new Compressor(image, {
        quality: 0.9,
        maxHeight: 1080,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      }),
  );
