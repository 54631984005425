type ParserType = (obj: Record<string, any>) => Record<string, any>;

const isNumeric = (string: string): boolean =>
  !isNaN(+string) && !isNaN(parseFloat(string));

const isObject = (param: any) =>
  typeof param === 'object' && param !== null && !Array.isArray(param);

export const nullsIntoEmptyStrings: ParserType = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map((entry) => {
      if (isObject(entry[1]))
        return [entry[0], nullsIntoEmptyStrings(entry[1])];

      return entry[1] === null ? [entry[0], ''] : [entry[0], entry[1]];
    }),
  );

export const numberStringsIntoNumbers: ParserType = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map((entry) => {
      if (isObject(entry[1]))
        return [entry[0], numberStringsIntoNumbers(entry[1])];

      return isNumeric(entry[1]) ? [entry[0], +entry[1]] : [entry[0], entry[1]];
    }),
  );

export const objectIntoQueryString = (obj: Record<string, any>) =>
  Object.entries(obj)
    .map((entry, idx) => {
      const key = entry[0];
      const value = entry[1];
      const objLength = Object.keys(obj).length;
      const prefix = idx === 0 ? '?' : '';
      const sufix = idx !== objLength - 1 ? '&' : '';
      let queryString = '';

      if (Array.isArray(value) || isObject(value))
        queryString = `${key}=${JSON.stringify(value)}`;
      else queryString = `${key}=${value}`;

      return `${prefix}${queryString}${sufix}`;
    })
    .join('');
