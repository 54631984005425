import { InputText, InputTextProps } from './';
import { VibSpinner } from '@vibTheme/components/VibSpinner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputDocument from './InputDocument';

export type InputExternalValidationProps = InputTextProps & {
  inputType?: 'text' | 'document';
  isLoading: boolean;
  isValid: boolean;
};

export const InputExternalValidation = (
  props: InputExternalValidationProps,
) => {
  const { inputType = 'text', isLoading, isValid, ...restProps } = props;

  const EndAdornment = () => (
    <div className="flex items-center">
      {isLoading ? (
        <VibSpinner size={20} />
      ) : isValid ? (
        <CheckCircleIcon className="text-green-500" />
      ) : null}

      {props.InputProps?.endAdornment}
    </div>
  );

  const resolveInputType = () => {
    switch (inputType) {
      case 'text':
        return (
          <InputText
            {...restProps}
            InputProps={{ endAdornment: <EndAdornment /> }}
          />
        );

      case 'document':
        return (
          <InputDocument
            {...restProps}
            InputProps={{ endAdornment: <EndAdornment /> }}
          />
        );
    }
  };

  return resolveInputType();
};
