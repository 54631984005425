import { ReactNode } from 'react';
import { TextField, StandardTextFieldProps } from '@mui/material';
import InputMask, { Props as ReactInputMaskProps } from 'react-input-mask';
import { Control, Controller } from 'react-hook-form';
import { styles } from './styles';
import { twMerge } from 'tailwind-merge';

export type InputTextProps = Omit<StandardTextFieldProps, 'value'> & {
  name: string;
  control: Control<any>;
  mask?: ReactInputMaskProps['mask'];
  onChangeCallback?: InputTextProps['onChange'];
  onBlurCallback?: InputTextProps['onBlur'];
};

export const InputText = ({
  name,
  control,
  defaultValue,
  mask,
  onChangeCallback,
  onBlurCallback,
  ...restProps
}: InputTextProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      // Retira 'ref' do objeto 'field'
      // https://github.com/react-hook-form/react-hook-form/issues/9126#issuecomment-1370843816
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field } }) => {
        const handleChange: InputTextProps['onChange'] = (event) => {
          field.onChange(event);
          onChangeCallback?.(event);
        };

        const handleBlur: InputTextProps['onBlur'] = (event) => {
          field.onBlur();
          onBlurCallback?.(event);
        };

        return (
          <InputMask
            {...field}
            mask={mask ?? ''}
            maskChar=""
            disabled={restProps.disabled}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {
              (() => (
                <TextField
                  {...field}
                  {...restProps}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={twMerge(styles, restProps.className)}
                />
              )) as unknown as ReactNode
            }
          </InputMask>
        );
      }}
    />
  );
};
