import { Grid, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, DataGridProps } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import VibExportReport, { VibExportReportProps } from '../VibExportReport';

export type VibDataGridProps = DataGridProps & {
  VibExportReportProps?: VibExportReportProps;
};

export type VibDataGridColDef = GridColDef;

export default function VibDataGrid(props: VibDataGridProps) {
  const { columns, VibExportReportProps, ...restProps } = props;

  const myDataGridRef = useRef<HTMLDivElement | null>(null);

  const [data, setData] = useState<any>();
  const [widthsToApply, setWidthsToApply] = useState<any>([]);

  const mappedWidths: any = [];

  useEffect(() => {
    autoSizeColumns();
  }, [data]);

  const autoSizeColumns = () => {
    if (!data) {
      const cellsContent = myDataGridRef.current?.querySelectorAll(
        '.MuiDataGrid-cellContent',
      );

      const headerContent = myDataGridRef.current?.querySelectorAll(
        '.MuiDataGrid-columnHeaderTitle',
      );

      if (!cellsContent || !headerContent) {
        setTimeout(autoSizeColumns, 1000);
        return;
      }

      if (cellsContent.length === 0 || headerContent.length === 0) {
        setTimeout(autoSizeColumns);
        return;
      } else {
        setData([...headerContent, ...cellsContent]);
      }
    }

    if (data) {
      const rowsIterator =
        props.rows.length < 100 ? props.rows.length + 1 : 101;
      //TODO: ensure that rows.length non-limited size will not brake the application flow;

      const elementsGroupedByColumn: any[] = [];
      columns.forEach((_col, idx) => {
        const tempArray = [];

        for (let i = 0; i < rowsIterator; i++) {
          let iterator = 0;
          iterator = idx + i * columns.length;
          tempArray.push(data[iterator]);
        }
        elementsGroupedByColumn.push(tempArray);
      });

      elementsGroupedByColumn.forEach((col) => {
        const widestTextOnColumn = col.reduce(
          (WidestText: any, currentText: any) => {
            return currentText
              ? Math.max(WidestText, currentText?.scrollWidth)
              : WidestText;
          },
          0,
        );
        mappedWidths.push(widestTextOnColumn + 24);
      });
      setWidthsToApply(mappedWidths);
    }
  };

  return (
    <Grid container rowSpacing={2} direction="column">
      {VibExportReportProps && (
        <Grid item>
          <VibExportReport {...VibExportReportProps} />
        </Grid>
      )}
      <Grid item>
        <DataGrid
          classes={{ footerContainer: `${VibExportReportProps && 'mr-16'}` }}
          disableSelectionOnClick
          autoHeight
          {...restProps}
          ref={myDataGridRef}
          columns={columns.map((col, idx) => ({
            ...col,
            flex: 1,
            minWidth: widthsToApply[idx],
          }))}
          rowsPerPageOptions={[100, 200, 500, 1000, 2000]}
          components={{
            LoadingOverlay: LinearProgress,
          }}
        />
      </Grid>
    </Grid>
  );
}
