import { TextField, StandardTextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export type InputTextAreaProps = Omit<StandardTextFieldProps, 'value'> & {
  name: string;
  control: Control<any>;
  onChangeCallback?: InputTextAreaProps['onChange'];
  onBlurCallback?: InputTextAreaProps['onBlur'];
};

export const InputTextArea = (props: InputTextAreaProps) => {
  const {
    name,
    control,
    defaultValue,
    onChangeCallback,
    onBlurCallback,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      // Retira 'ref' do objeto 'field'
      // https://github.com/react-hook-form/react-hook-form/issues/9126#issuecomment-1370843816
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field } }) => {
        const handleChange: InputTextAreaProps['onChange'] = (event) => {
          field.onChange(event);
          onChangeCallback?.(event);
        };

        const handleBlur: InputTextAreaProps['onBlur'] = (event) => {
          field.onBlur();
          onBlurCallback?.(event);
        };

        return (
          <TextField
            {...field}
            rows={5}
            {...restProps}
            onChange={handleChange}
            onBlur={handleBlur}
            className={twMerge('', restProps.className)}
            multiline
          />
        );
      }}
    />
  );
};
