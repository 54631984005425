import { Menu, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from 'react';
import { parseFilters } from '@utils/parseFilters';
import useToastContext from '@contexts/ToastContext/hook';
import { Button } from '@vibTheme/components/_base/Button';
import { ApiReportUrls, exportVibReport } from '@api/reports';
import { ReportOutputType } from '@interfaces/reports';

export interface VibExportReportProps {
  fetchUrl: ApiReportUrls;
  fields?: any;
}

export default function VibExportReport({
  fetchUrl,
  fields = {},
}: VibExportReportProps) {
  const toast = useToastContext();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleExport(outputType: ReportOutputType) {
    let reportUrl = '';
    setIsLoading(true);
    handleClose();

    try {
      const filters = parseFilters(fields);
      const response = await exportVibReport(fetchUrl, {
        filters,
        outputType,
      });

      reportUrl = response.data.reportUrl || '';
    } catch (error) {
      toast.setContent('Não foi possível exportar o relatório', 'error');
      console.error(error);
    }

    if (reportUrl) {
      download(reportUrl);
    } else {
      toast.setContent('Relatórios vazios não podem ser exportados', 'warning');
    }
    setIsLoading(false);
  }

  async function download(reportUrl: string) {
    const link = document.createElement('a');
    link.href = reportUrl;
    link.click();
  }

  return (
    <div className="text-right">
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        endIcon={<FileDownloadIcon />}
        onClick={handleClick}
        loading={isLoading}
      >
        Exportar
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => handleExport(ReportOutputType.CSV)}
          className="w-28"
        >
          CSV
        </MenuItem>
        <MenuItem onClick={() => handleExport(ReportOutputType.XLSX)}>
          XLSX
        </MenuItem>
        <MenuItem onClick={() => handleExport(ReportOutputType.PDF)}>
          PDF
        </MenuItem>
      </Menu>
    </div>
  );
}
