import { ChangeEvent } from 'react';
import { Controller, Control } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { SwitchProps, Switch, FormControlLabel } from '@mui/material';

export type InputSwitchProps = Omit<SwitchProps, 'value' | 'checked'> & {
  name: string;
  label: string;
  control: Control<any>;
  onChangeCallback?: (event: ChangeEvent) => void;
};

export const InputSwitch = ({
  name,
  label,
  control,
  onChangeCallback,
  defaultChecked,
  ...restProps
}: InputSwitchProps) => {
  const SwitchStyles = '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={({ field }) => {
        const handleChange = (event: any) => {
          field.onChange(event);
          onChangeCallback?.(event);
        };

        return (
          <FormControlLabel
            control={
              <Switch
                {...restProps}
                onChange={handleChange}
                checked={field.value}
                inputProps={{ id: name }}
                className={twMerge(SwitchStyles, restProps.className)}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};
