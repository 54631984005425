import React, { useEffect, useState } from 'react';
import { InputText, InputTextProps } from './InputText';
import { isCpf } from '@utils/inputValidation';

export type InputDocumentProps = InputTextProps;

const resolveDocumentType = (document: string) =>
  isCpf(document) ? 'CPF' : 'CNPJ';

const InputDocument = (props: InputDocumentProps) => {
  const documentValue = props.control._formValues[props.name];
  const initialDocumentType = resolveDocumentType(documentValue);
  const [documentType, setDocumentType] = useState(initialDocumentType);

  const handleDocumentChange: InputTextProps['onChangeCallback'] = (event) => {
    const document = event.target.value.replace(/\D/g, '');
    setDocumentType(resolveDocumentType(document));

    props.onChangeCallback?.(event);
  };

  useEffect(() => {
    setDocumentType(resolveDocumentType(props.defaultValue || documentValue));
  }, [documentValue]);

  const dynamicMask =
    documentType === 'CPF' ? '999.999.999-999999' : '99.999.999/9999-99';

  return (
    <InputText
      {...props}
      mask={dynamicMask}
      onChangeCallback={handleDocumentChange}
    />
  );
};

export default InputDocument;
