import { z } from 'zod';
import { numberStringsIntoNumbers } from './parseObjectValues';
import { FormState } from 'react-hook-form';

export const validateCurrency = () =>
  z.preprocess((value) => {
    if (typeof value === 'number') return value;
    return value === '' ? null : (value as string).replace(',', '.');
  }, z.coerce.number({ invalid_type_error: 'Valor inválido.' }).nonnegative('O valor não pode ser negativo.').nullable());

// Usar somente a propriedade dirtyFields, do formState do RHF, para verificar se o formulário
// foi alterado é insuficiente porque os campos numéricos são convertidos de 'number' para 'string'
// pelo comportamento padrão de <inputs /> HTML.
export const isFormDirty = (
  defaultValues: any,
  newValues: any,
  options: IsFormDirtyOptions = { transformNumberStrings: true },
) => {
  const finalNewValues = options.transformNumberStrings
    ? numberStringsIntoNumbers(newValues)
    : newValues;

  return JSON.stringify(defaultValues) !== JSON.stringify(finalNewValues);
};

// A propriedade dirtyFields, do formState do RHF, retorna um objeto no formato:
// { <campoAlterado1>: true, <campoAlterado2>: true, ... }
export const stripNonChangedFields = (
  obj: Record<string, any>,
  dirtyFields: FormState<any>['dirtyFields'],
) =>
  Object.fromEntries(
    Object.entries(obj).filter((item) => dirtyFields[item[0]]),
  );

export const isCpf = (document: any) =>
  String(document).replace(/\D/g, '').length <= 11;

type IsFormDirtyOptions = {
  transformNumberStrings: boolean;
};
