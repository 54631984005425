import { useState } from 'react';
import { IconButton } from '@mui/material';
import InputAdornmentIcon from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputText, InputTextProps } from './InputText';

export type InputPasswordProps = InputTextProps;

export const InputPassword = (props: InputPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((show) => !show);

  return (
    <InputText
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornmentIcon position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornmentIcon>
        ),
      }}
    />
  );
};
